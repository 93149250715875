import type { PaginateResult } from "mongoose"
import type { AuthScope } from "./auth.store"

export enum SecurityEventType {
  CREATE_AUTHENTICATION_ACCOUNT = "CREATE_AUTHENTICATION_ACCOUNT",
  DELETE_AUTHENTICATION_ACCOUNT = "DELETE_AUTHENTICATION_ACCOUNT",
  BLOCK_USER = "BLOCK_USER",
  UNBLOCK_USER = "UNBLOCK_USER",
  DELETE_RELATIONSHIP = "DELETE_RELATIONSHIP",
  CREATE_RELATIONSHIP = "CREATE_RELATIONSHIP",
  LOGGED_IN = "LOGGED_IN",
  LOGGED_OUT = "LOGGED_OUT",
  LOGGED_IN_ORGANIZATION = "LOGGED_IN_ORGANIZATION",
  RESET_PASSWORD = "RESET_PASSWORD",
  API_KEY_CREATED = "API_KEY_CREATED",
  API_KEY_RENEWED = "API_KEY_RENEWED",
  API_KEY_INFO_UPDATED = "API_KEY_INFO_UPDATED",
}

export interface SecurityLog {
  _id: string,
  userSource?: {_id: string, description: string},
  organizationSource?: {_id: string, description: string},
  event: SecurityEventType,
  userTarget?: {_id: string, description: string},
  organizationTarget?: {_id: string, description: string},
  scopesAffected?: Array<AuthScope>,
  updated?: string,
  createdAt: string
}

export interface LoginChartDataOptions {
  fromDate?: string
  toDate?: string
  timeUnit?: "hour" | "day" | "week" | "month" | "year",
  organizationTarget?: string
}

export const useSecurityLogStore = defineStore("security-logs", () => {
  const getSecurityLogsByPage = async (queryParameters: Record<string, unknown>) => {
    const query = getUrlSearchParams(queryParameters)

    const { data } = await useSamApi<PaginateResult<SecurityLog>>(`/security-logs?${query}`)
    return data
  }

  const getLoginsByOrganization = async (organizationId: string, limit?: number) => {
    const { data } = await useSamApi<Array<SecurityLog>>(`/security-logs/history/organization/${organizationId}/login?limit=${limit}`)
    return data
  }

  const getLoginsByUser = async (userId: string, limit?: number) => {
    const { data } = await useSamApi<Array<SecurityLog>>(`/security-logs/history/user/${userId}/login?limit=${limit}`)
    return data
  }

  const getLoginChartData = async (options?: LoginChartDataOptions) => {
    const query = getUrlSearchParams(options)
    const { data } = await useSamApi<Array<{ date: string, count: number }>>(`/security-logs/history/login/chart-data?${query}`)
    return data
  }

  return {
    getSecurityLogsByPage,
    getLoginsByOrganization,
    getLoginsByUser,
    getLoginChartData,
  }
})